import { Component, OnInit } from "@angular/core";
import { NavService } from "../../../../services/nav.service";
import { TranslateService } from "@ngx-translate/core";
import { I18nService } from "src/app/shared/services/i18n.service";

@Component({
  selector: "app-languages",
  templateUrl: "./languages.component.html",
  styleUrls: ["./languages.component.scss"],
})
export class LanguagesComponent implements OnInit {
  public language: boolean = false;

  public languages: any[] = [
    {
      language: "Turkish",
      code: "tr",
      icon: "tr",
    },
    // {
    //   language: "English",
    //   code: "en",
    //   type: "US",
    //   icon: "us",
    // },

    {
      language: "Français",
      code: "fr",
      icon: "fr",
    },
  ];

  public selectedLanguage: any = {
    language: "Turkish",
    code: "fr",
    type: "FR",
    icon: "fr",
  };

  constructor(
    public navServices: NavService,
    private translateService: TranslateService,
    private langEmit: I18nService
  ) {
    this.translateService.use("fr");
  }
  //
  ngOnInit() {}

  changeLanguage(lang) {
    this.selectedLanguage = lang;

    this.selectedLang = this.selectedLang.toLocaleLowerCase();
    this.translateService.use(lang.code.toString().toLocaleLowerCase());
    this.langEmit.getEmitServis(lang.code.toString().toLocaleLowerCase());
    // console.log(lang.code);
  }

  public selectedLang = "EN";
}
