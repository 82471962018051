import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: "root",
})
export class MenuJsonService {
  user;
  token;
  reqHeader;
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private spinner: NgxSpinnerService,
    public msj: ToastrService
  ) {
    this.user = JSON.parse(JSON.stringify(sessionStorage.getItem("user")));
    this.token = sessionStorage.getItem("access_token");
    this.reqHeader = new HttpHeaders({
      accept: "*/*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
      //'Cache-Control':  'no-cache'
    });
  }

  baseURL = "https://cmsapi.monassist.com/api/";

  async get(endPoint: string): Promise<any> {
    this.spinner.show();
    return this.httpClient
      .get<any>(this.baseURL + `${endPoint}`, { headers: this.reqHeader })
      .toPromise()
      .then((res: any) => {
        this.spinner.hide();
        return res;
      })
      .catch((err) => {
        this.spinner.hide();
        return err;
      });
  }

  async post(endPoint: string, body: any): Promise<any> {
    this.spinner.show();
    return this.httpClient
      .post<any>(this.baseURL + `${endPoint}`, body, {
        headers: this.reqHeader,
      })
      .toPromise()
      .then((res: any) => {
        this.spinner.hide();

        this.msj.success(`${res.messages}`, "Başarılı", {
          progressBar: true,
        });

        return res;
      })
      .catch((err) => {
        this.spinner.hide();
        if (err.status > 399 || err.status < 500) {
          err.error.messages.forEach((element) => {
            this.msj.warning(`${element}`, "Warning");
          });
          // return err.error;
        }

        if (err.status > 499) {
          err.error.messages.forEach((element) => {
            this.msj.error(`${element}`, "Warning");
          });
        }
      });
  }

  async put(endPoint: string, body = {}): Promise<any> {
    console.log(body);
    return this.httpClient
      .put<any>(this.baseURL + `${endPoint}`, body, {
        headers: this.reqHeader,
      })
      .toPromise();
  }

  async delete(endPoint: string): Promise<any> {
    this.spinner.show();
    return this.httpClient
      .delete<any>(this.baseURL + `${endPoint}`, {
        headers: this.reqHeader,
      })
      .toPromise()
      .catch((err) => {
        this.spinner.hide();

        if (err.status > 399 || err.status < 500) {
          err.error.messages.forEach((element) => {
            this.msj.warning(`${element}`, "Warning");
          });
        }

        if (err.status > 499) {
          err.error.messages.forEach((element) => {
            this.msj.error(`${element}`, "Error");
          });
        }
      })
      .then((res: any) => {
        this.spinner.hide();
        return res;
      });
  }
}
