import {
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  ViewChild,
} from "@angular/core";

@Directive({
  selector: "[appSort]",
})
export class SortDirective {
  @Input() appSort: string; // Sıralama yapılacak özellik adı
  @Input() sortColumn: string; // Şu anda sıralama yapılan sütun
  @Input() sortDirection: string; // Sıralama yönü
  @Input() sortIcon: string; // Sıralama yönü
  @Input() label: string; // Sütun başlığı
  @Input() columnKey: string;
  @Output() sortChange: EventEmitter<{
    sortBy: string;
    sortDirection: number;
  }> = new EventEmitter();

  private sortState: SortState = { sortBy: "", sortDirection: 1 }; // Sıralama durumu nesnesi

  constructor() {}
  clicks = 0;
  columnCtrl = false;
  @HostBinding("class.asc") get asc() {
    return this.sortColumn === this.appSort && this.sortDirection === "asc";
  }
  @HostBinding("class.desc") get desc() {
    return this.sortColumn === this.appSort && this.sortDirection === "desc";
  }

  @HostBinding("innerHTML") get content(): string {
    if (this.sortColumn === this.columnKey) {
      if (this.sortDirection === "asc") {
        return `${this.label} <i class="fa fa-sort-asc" aria-hidden="true"></i>`;
      } else if (this.sortDirection === "desc") {
        return `${this.label} <i class="fa fa-sort-desc" aria-hidden="true"></i>`;
      }
    }
    return `${this.label} <i #sortIcon class="fa fa-sort" aria-hidden="true"></i>`;
  }

  @HostListener("click")
  sort() {
    if (this.sortColumn == "" || this.sortColumn == this.columnKey) {
      this.clicks++;
    } else {
      this.clicks = 1;
    }
    if (this.clicks == 1) {
      this.sortDirection = "asc";
      this.sortColumn = this.columnKey;
    } else if (this.clicks == 2) {
      this.sortColumn = this.columnKey;
      this.sortDirection = "desc";
    } else if (this.clicks == 3) {
      this.sortColumn = "";
      this.clicks = 0;
    }

    this.sortChange.emit({
      sortBy: this.appSort,
      sortDirection: this.sortDirection === "asc" ? 1 : -1,
    });
  }
}

interface SortState {
  sortBy: string;
  sortDirection: number;
}
