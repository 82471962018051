import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, of, switchMap, tap, throwError } from "rxjs";
import { AuthService } from "./auth.service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class HttpInterceptorService implements HttpInterceptor {
  constructor(private auth: AuthService, private router: Router) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const callNext = () => next.handle(req);
    let user = this.auth.getUserFromStorage();
    const noAction = req.url.indexOf("Auth") !== -1;
    return callNext().pipe(
      catchError((error) => {
        if (!noAction && error.status === 401) {
          return this.auth.refreshToken().pipe(
            switchMap((res: any) => {
              let refreshedUser: any = res;
              sessionStorage.setItem("user", JSON.stringify(refreshedUser));

              sessionStorage.setItem(
                "refreshToken",
                JSON.stringify(refreshedUser.data.refreshToken)
              );
              sessionStorage.setItem("access_token", refreshedUser.data.token);
              user = this.auth.getUserFromStorage();
              let updatedRequest = req.clone({
                headers: req.headers.set(
                  "Authorization",
                  `Bearer ${res?.data.token}`
                ),
              });

              window.location.reload();
              return next.handle(updatedRequest);
            }),
            catchError((err) => {
              if (err.status === 400) {
                const isSpecificAPI = err.url.indexOf("RefreshToken") !== -1;
                if (isSpecificAPI && err.status === 400) {
                  this.auth.doLogout();
                }
              }

              return throwError(err);
            })
          );
        }

        return throwError(error);
      })
    );
  }
}
