import {
  Component,
  ViewEncapsulation,
  HostListener,
  OnInit,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Menu, NavService } from "../../services/nav.service";
import { LayoutService } from "../../services/layout.service";
import { I18nService } from "../../services/i18n.service";
import { MenuJsonService } from "../../services/menuJson.service";
import { MenuJsonEmitService } from "../../services/emits/menuJsonEmit.service";
import { MenuJsonModel } from "../../model/menuJsonModel";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent implements OnInit {
  public iconSidebar: boolean;
  public menuItems: Menu[] = [];
  public url: any;
  public fileurl: any;

  // For Horizontal Menu
  public margin: any = 0;
  public width: any = window.innerWidth;
  public leftArrowNone: boolean = true;
  public rightArrowNone: boolean = false;

  constructor(
    private router: Router,
    public navServices: NavService,
    public layout: LayoutService,
    private i18nService: I18nService,
    private menuHttp: MenuJsonService,
    private menuEmit: MenuJsonEmitService
  ) {
    this.navServices.items.subscribe((menuItems) => {
      this.menuItems = menuItems;
      console.log(menuItems);
    });
    this.i18nService.getEmit.subscribe((res) => {
      res == undefined ? (this.lang = "fr") : (this.lang = res);
    });
  }

  ngOnInit(): void {
    // Collapse sidebar on smaller screens
    if (window.innerWidth < 991) {
      this.navServices.collapseSidebar = true;
    }

    // Subscribe to router events for collapsing sidebar
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (window.innerWidth < 991) {
          this.navServices.collapseSidebar = true;
        }
      }
    });
    this.i18nService.getEmit.subscribe((res) => {
      res == undefined ? (this.lang = "fr") : (this.lang = res);
    });
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    this.width = event.target.innerWidth - 500;
  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
  }

  // Active Nave state
  setNavActive(item: Menu) {
    this.menuItems.forEach((menuItem) => {
      if (menuItem !== item) {
        menuItem.active = false;
      }
      if (menuItem.children && menuItem.children.includes(item)) {
        menuItem.active = true;
      }
      if (menuItem.children) {
        menuItem.children.forEach((submenuItems) => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
  }

  // Click Toggle menu
  toggletNavActive(item: Menu) {
    if (!item.active) {
      this.menuItems.forEach((a) => {
        if (this.menuItems.includes(item)) {
          a.active = false;
        }
        if (!a.children) {
          return false;
        }
        a.children.forEach((b) => {
          if (a.children.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
  }

  // For Horizontal Menu
  scrollToLeft() {
    if (this.margin >= -this.width) {
      this.margin = 0;
      this.leftArrowNone = true;
      this.rightArrowNone = false;
    } else {
      this.margin += this.width;
      this.rightArrowNone = false;
    }
  }

  scrollToRight() {
    if (this.margin <= -3051) {
      this.margin = -3464;
      this.leftArrowNone = false;
      this.rightArrowNone = true;
    } else {
      this.margin += -this.width;
      this.leftArrowNone = false;
    }
  }

  inputHide = true;

  editIcon(value: number) {
    this.inputHide = false;
  }

  cancelIcon() {
    this.i18nService.getEmitServis(this.lang);
    this.inputHide = true;
  }
  lang;
  async putMenuData() {
    let data = {
      1: "",
      4: "",
      6: "",
      7: "",
      8: "",
      9: "",
      10: "",
      11: "",
      12: "",
      13: "",
      14: "",
      15: "",
      31: "",
      32: "",
      33: "",
      34: "",
      35: "",
      36: "",
      37: "",
      38: "",
      39: "",
      40: "",
      41: "",
      42: "",
      43: "",
      44: "",
      45: "",
      46: "",
      47: "",
      48: "",
      57: "",
      58: "",
      59: "",
    };

    for (let index = 0; index < this.menuItems.length; index++) {
      let element = this.menuItems[index];
      var children = element.children;

      if (element.menuKod in data) {
        data[element.menuKod] = element.title;
      }

      if (children && children.length > 0) {
        for (let i = 0; i < children.length; i++) {
          const childElement = children[i];

          if (childElement.menuKod in data) {
            data[childElement.menuKod] = childElement.title;
          }
          console.log(children);
        }
      }
    }

    console.log(data);

    const res = await this.menuHttp
      .put(`LanguageSettings/MenuLanguageCollectiveUpdate/${this.lang}`, data)
      .then((res) => {
        console.log(res);
      });

    this.inputHide = true;
    this.i18nService.getEmitServis(this.lang);
  }
}
