import { Directive, ElementRef, HostListener, Self } from "@angular/core";
import { NgModel } from "@angular/forms";

@Directive({
  selector: "[appLowerCaseToString]",
})
export class LowerCaseToStringDirective {
  constructor(private el: ElementRef, @Self() private ngModel: NgModel) {}

  @HostListener("input", ["$event.target.value"]) onInputChange(value: string) {
    const lowerCaseValue = value.toLocaleLowerCase("tr-TR");
    this.ngModel.valueAccessor.writeValue(lowerCaseValue); // input alanını günceller
    this.ngModel.viewToModelUpdate(lowerCaseValue); // modeli günceller
  }

  // private transformValue(value: string): string {
  //   const turkishChars = {
  //     ç: "c",
  //     ğ: "g",
  //     ı: "i",
  //     ö: "o",
  //     ş: "s",
  //     ü: "u",
  //     İ: "i",
  //     Ç: "c",
  //     Ğ: "g",
  //     Ö: "o",
  //     Ş: "s",
  //     Ü: "u",
  //   };
  //   let newValue = value
  //     .replace(/ç|ğ|ı|ö|ş|ü|İ|Ç|Ğ|Ö|Ş|Ü/g, function (match) {
  //       return turkishChars[match];
  //     })
  //     .toLowerCase();
  //   return newValue;
  // }
}
