import { AfterViewInit, Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as feather from "feather-icons";
import { LayoutService } from "../../../services/layout.service";
import { NavService } from "../../../services/nav.service";

@Component({
  selector: "app-content",
  templateUrl: "./content.component.html",
  styleUrls: ["./content.component.scss"],
})
export class ContentComponent implements OnInit, AfterViewInit {
  constructor(
    private route: ActivatedRoute,
    public navServices: NavService,
    public layout: LayoutService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.layout.config.settings.layout = params.layout
        ? params.layout
        : this.layout.config.settings.layout;
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      feather.replace();
    });
  }

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : "";
  }

  get layoutClass() {
    return "compact-wrapper";
  }

  toggleSidebar() {
    if (this.isMobileScreen()) {
      this.navServices.collapseSidebar = true;
    }
  }

  isMobileScreen(): boolean {
    return this.navServices.screenWidth.getValue() < 991 ? true : false;
  }

  ngOnInit() {}
}
