import { AfterViewInit, Injectable, OnDestroy, OnInit } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";
import { I18nService } from "./i18n.service";
import { MenuJsonService } from "./menuJson.service";
import { MenuJsonEmitService } from "./emits/menuJsonEmit.service";

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  gorme?: boolean;
  silme?: boolean;
  guncelleme?: boolean;
  menuKod?: number;
  ustMenuID?: number;
  activeIndex?: number;
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy, AfterViewInit, OnInit {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(
    window.innerWidth
  );

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;
  getSession;
  getMenu;
  MENUITEMS: Menu[] = [];
  //translations = require("../../../assets/i18n/menu.json");
  translations: any;
  constructor(
    private router: Router,
    private i18nService: I18nService,

    private menuHttp: MenuJsonService,
    private menuEmit: MenuJsonEmitService
  ) {
    this.i18nService.getEmit.subscribe((value) => {
      let currentLang = value;
      value == undefined ? (currentLang = "fr") : (currentLang = value);
      setTimeout(() => {
        this.MENUITEMS = [];
      }, 1000);
      this.menuHttp
        .get(`LanguageSettings/MenuLanguageGetList/${currentLang}`)
        .then(async (res) => {
          this.setScreenWidth(window.innerWidth);
          fromEvent(window, "resize")
            .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
            .subscribe((evt: any) => {
              this.setScreenWidth(evt.target.innerWidth);
              if (evt.target.innerWidth < 991) {
                this.collapseSidebar = true;
                this.megaMenu = false;
                this.levelMenu = false;
              }
              if (evt.target.innerWidth < 1199) {
                this.megaMenuColapse = true;
              }
            });
          if (window.innerWidth < 991) {
            this.router.events.subscribe((event) => {
              this.collapseSidebar = true;
              this.megaMenu = false;
              this.levelMenu = false;
            });
          }
          this.translations = res.data;
          let currentLang = "fr";
          let arr = [];

          // value == undefined ? (currentLang = "tr") : (currentLang = value);
          // setTimeout(() => {
          //   this.MENUITEMS = [];
          // }, 1000);
          this.createMenu();
          arr.push(this.translations);
          let userData = JSON.parse(sessionStorage.getItem("user"));
          let menuData = userData ? userData.data.menu.ustMenuListesi : [];

          for (let i of menuData) {
            let item2Value = arr[0][i.menuKod];

            i.linkAd = item2Value;
            for (let j of i.baslikMenuListesi) {
              let item3Value = arr[0][j.menuKod];
              j.linkAd = item3Value;
            }
          }

          for (let index = 0; index < menuData.length; index++) {
            const element = menuData[index];
            let childrenItems = [];

            for (
              let innerIndex = 0;
              innerIndex < element.baslikMenuListesi.length;
              innerIndex++
            ) {
              const element2 = element.baslikMenuListesi[innerIndex];
              let subChildrenItems = [];

              // Üçüncü seviye menü öğeleri için kontrol
              if (
                element2.altMenuListesi &&
                element2.altMenuListesi.length > 0
              ) {
                for (
                  let subIndex = 0;
                  subIndex < element2.altMenuListesi.length;
                  subIndex++
                ) {
                  const subElement = element2.altMenuListesi[subIndex];
                  subChildrenItems.push({
                    menuKod: subElement.menuKod,
                    path: subElement.link,
                    title: subElement.linkAd,
                    type: "link",
                  });
                }
              }

              childrenItems.push({
                menuKod: element2.menuKod,
                path:
                  element2.altMenuListesi && element2.altMenuListesi.length > 0
                    ? null
                    : element2.link,
                title: element2.linkAd,
                type:
                  element2.altMenuListesi && element2.altMenuListesi.length > 0
                    ? "sub"
                    : "link",
                active: false,
                children: subChildrenItems.length > 0 ? subChildrenItems : null,
              });
            }

            this.MENUITEMS.push({
              menuKod: element.menuKod,
              title: element.linkAd,
              icon: element.icon || "cofont-businessman",
              type:
                element.baslikMenuListesi &&
                element.baslikMenuListesi.length > 0
                  ? "sub"
                  : "link",
              badgeType: "light-primary",
              active: false,
              children: childrenItems,
            });
          }
          this.MENUITEMS.unshift({
            menuKod: 1,
            path: "/dashboard/dashboard-page",
            title: arr[0]["1"],
            icon: "icofont icofont-home",
            type: "link",
            bookmark: true,
          });

          this.items.next(this.MENUITEMS);
        });
    });
  }
  ngOnInit(): void {}

  async getMenuData() {}

  ngAfterViewInit(): void {}

  createMenu() {}

  ngOnDestroy() {
    // this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }
  items = new BehaviorSubject<any[]>(this.MENUITEMS);
}
