import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  Validators,
  FormGroup,
  AbstractControl,
} from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { LayoutService } from "src/app/shared/services/layout.service";
import { AuthService } from "../../shared/services/auth.service";
import { faRotate, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  fontawesome = [
    {
      faRotate: faRotate,
      faEye: faEye,
      faEyeSlash: faEyeSlash,
    },
  ];

  public newUser = false;
  public loginForm: FormGroup;
  public show: boolean = false;
  public errorMessage: any;

  constructor(
    public layoutService: LayoutService,
    private router: Router,
    private auth: AuthService,
    private fb: FormBuilder,
    private httpClient: HttpClient,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.valitadion();
    this.captcha();
  }

  captchaData!: string;
  password!: string;
  user!: string;
  captchaText!: any;
  key!: string;
  valitadion() {
    this.loginForm = this.fb.group({
      userName: ["", [Validators.required]],
      password: ["", [Validators.required]],
      recaptcha: ["", [Validators.required, Validators.minLength(4)]],
    });
  }
  get f(): { [key: string]: AbstractControl } {
    return this.loginForm.controls;
  }
  getCode!: string;
  async captcha() {
    this.spinner.show();
    this.httpClient
      .get("https://cmsapi.monassist.com/api/Auth/CaptchaUretici")
      .subscribe(
        (res: any) => {
          this.captchaData = "data:image/png;base64," + res?.data.value;
          this.key = res?.data.key;
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
        }
      );
  }
  login() {
    let userData = {
      userName: this.user,
      password: this.password,
      captchaKey: this.key,
      captchaDeger: this.captchaData,
    };
    this.auth.signIn(userData, this.user);
  }

  showPassword() {
    this.show = !this.show;
  }
}
