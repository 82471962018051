import { Routes } from "@angular/router";

export const content: Routes = [
  {
    path: "dashboard",
    loadChildren: () =>
      import("../../components/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: "system-module",
    loadChildren: () =>
      import("../../components/system-module/system-module.module").then(
        (m) => m.SystemModuleModule
      ),
  },
  {
    path: "employee-module",
    loadChildren: () =>
      import("../../components/employee-module/employee-module.module").then(
        (m) => m.EmployeeModuleModule
      ),
  },
  {
    path: "construction-module",
    loadChildren: () =>
      import(
        "../../components/construction-module/construction-module.module"
      ).then((m) => m.ConstructionModuleModule),
  },
  {
    path: "description-module",
    loadChildren: () =>
      import(
        "../../components/description-module/description-module.module"
      ).then((m) => m.DescriptionModuleModule),
  },
  {
    path: "material-module",
    loadChildren: () =>
      import("../../components/material/material.module").then(
        (m) => m.MaterialModule
      ),
  },
  // {construction
  //   path: "simple-page",
  //   loadChildren: () => import("../../components/simple-page/simple-page.module").then((m) => m.SimplePageModule),
  // },
];
