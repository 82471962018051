import {
  Directive,
  ElementRef,
  HostListener,
  Output,
  EventEmitter,
} from "@angular/core";

@Directive({
  selector: "[maxDate]",
})
export class MaxDateDirective {
  constructor(private el: ElementRef) {}

  @Output() ngModelChange = new EventEmitter();

  @HostListener("input", ["$event"]) onInputChange(event) {
    this.checkMaxDate(event.target);
  }

  @HostListener("blur", ["$event"]) onBlur(event) {
    this.checkMaxDate(event.target);
  }

  private checkMaxDate(input: HTMLInputElement) {
    const inputValue = input.value;
    if (inputValue) {
      const inputDate = new Date(inputValue);
      const maxAllowedDate = new Date("2099-12-31");
      if (inputDate > maxAllowedDate) {
        const formattedDate = this.formatDate(maxAllowedDate);
        input.value = formattedDate;
        this.ngModelChange.emit(formattedDate); // ngModelChange olayını tetikle
      } else {
        this.ngModelChange.emit(inputValue); // Değer belirlenen tarihten büyük değilse, mevcut değeri emit et
      }
    }
  }

  private formatDate(date: Date): string {
    return date.toISOString().split("T")[0];
  }
}
