import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  mapToCanActivate,
} from "@angular/router";
import { Observable } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class AdminGuard {
  constructor(public router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let user = JSON.parse(sessionStorage.getItem("user"));
    if (!user || user.data.token === null) {
      this.router.navigate(["/auth/login"]);
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("access_token");

      return true;
    } else if (user) {
      if (!Object.keys(user).length) {
        this.router.navigate(["/auth/login"]);
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("access_token");

        return true;
      }
    }
    // return true;
  }
}
