import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";

import { catchError, Observable, retry, throwError } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class HttpService {
  user;
  token;
  reqHeader;
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private spinner: NgxSpinnerService,
    public msj: ToastrService
  ) {
    this.user = JSON.parse(JSON.stringify(sessionStorage.getItem("user")));
    this.token = sessionStorage.getItem("access_token");
    this.reqHeader = new HttpHeaders({
      accept: "*/*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
      //'Cache-Control':  'no-cache'
    });
  }

  baseURL = "https://cmsapi.monassist.com/api/";

  async get(endPoint: string): Promise<any> {
    this.spinner.show();
    return this.httpClient
      .get<any>(this.baseURL + `${endPoint}`, { headers: this.reqHeader })
      .toPromise()
      .then((res: any) => {
        this.spinner.hide();
        return res;
      })
      .catch((err) => {
        this.spinner.hide();
        return err;
      });
  }
  async post(endPoint: string, body: any): Promise<any> {
    this.spinner.show();
    return this.httpClient
      .post<any>(this.baseURL + `${endPoint}`, body, {
        headers: this.reqHeader,
      })
      .toPromise()
      .then((res: any) => {
        this.spinner.hide();

        this.msj.success(`${res.messages}`, "Başarılı", {
          progressBar: true,
        });

        return res;
      })
      .catch((err) => {
        this.spinner.hide();
        if (err.status > 399 || err.status < 500) {
          err.error.messages.forEach((element) => {
            this.msj.warning(`${element}`, "Warning");
          });
          // return err.error;
        }

        if (err.status > 499) {
          err.error.messages.forEach((element) => {
            this.msj.error(`${element}`, "Warning");
          });
        }
      });
  }

  async put(endPoint: string, body: any): Promise<any> {
    this.spinner.show();
    return this.httpClient
      .put<any>(this.baseURL + `${endPoint}`, body, {
        headers: this.reqHeader,
      })
      .toPromise()
      .catch((err) => {
        this.spinner.hide();
        if (err.status > 399 || err.status < 500) {
          err.error.messages.forEach((element) => {
            this.msj.warning(`${element}`, "Warning");
          });
        }

        if (err.status > 499) {
          err.error.messages.forEach((element) => {
            this.msj.error(`${element}`, "Warning");
          });
        }
      })
      .then((res: any) => {
        this.spinner.hide();

        this.msj.success(`${res.messages}`, "Başarılı", {
          progressBar: true,
        });

        return res;
      });
  }

  async delete(endPoint: string): Promise<any> {
    this.spinner.show();
    return this.httpClient
      .delete<any>(this.baseURL + `${endPoint}`, {
        headers: this.reqHeader,
      })
      .toPromise()
      .catch((err) => {
        this.spinner.hide();

        if (err.status > 399 || err.status < 500) {
          err.error.messages.forEach((element) => {
            this.msj.warning(`${element}`, "Warning");
          });
        }

        if (err.status > 499) {
          err.error.messages.forEach((element) => {
            this.msj.error(`${element}`, "Error");
          });
        }
      })
      .then((res: any) => {
        this.spinner.hide();
        return res;
      });
  }

  async httpPostFile(endPoint, data: any): Promise<any> {
    let token = sessionStorage.getItem("access_token");
    var reqHeader = new HttpHeaders({
      // 'ApiKey': 'Pnc1a60tc7Qf4WzoD9El8Gw7szSbN6CBqrP8Tqj3vRo=.KcXxS6dUi9yd28tixsb7w47KZ8F4F2zR',
      // 'Content-Type':"multipart/form-data; boundary=----WebKitFormBoundaryq2lzt9nK5blfZIf0",
      Authorization: "Bearer " + token,
      //'Cache-Control':  'no-cache'
    });
    return this.httpClient
      .put(`${this.baseURL}${endPoint}`, data, {
        headers: reqHeader,
        reportProgress: true,
      })
      .toPromise()
      .catch((err) => {
        if (err.status === 401) {
          return this.httpClient
            .get(`${this.baseURL}/${endPoint}`, {
              headers: reqHeader,
              reportProgress: true,
            })
            .toPromise()
            .catch((err) => {
              if (err.status === 401) {
                return this.httpClient
                  .get(`${this.baseURL}/${endPoint}`, {
                    headers: reqHeader,
                    observe: "response",
                    responseType: "blob",
                  })
                  .toPromise()
                  .catch((err2) => {
                    if (err2.status === 401) {
                      // this.router.navigateByUrl("/auth/login");
                      window.location.reload();
                    }
                  });
              }
            });
        }
      });
  }

  async getFile(endPoint: string): Promise<HttpResponse<Blob>> {
    const url = `${this.baseURL}${endPoint}`;

    try {
      const res = await this.httpClient
        .get<Blob>(url, {
          headers: this.reqHeader,
          responseType: "blob" as "json",
          observe: "response",
        })
        .toPromise();

      this.spinner.hide();
      return res;
    } catch (err) {
      this.spinner.hide();

      throw err;
    }
  }

  async uploadFile(
    endPoint,
    documentTypeId: number,
    tableId: number,
    documentName: string,
    file: File
  ): Promise<any> {
    this.spinner.show();
    const formData: FormData = new FormData();
    formData.append("DocumentTypeId", documentTypeId.toString());
    formData.append("TableId", tableId.toString());
    formData.append("DocumentName", documentName);
    formData.append("file", file, file.name);

    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.token,
    });

    try {
      const response = await this.httpClient
        .post(this.baseURL + endPoint, formData, { headers })
        .toPromise()
        .then((res: any) => {
          this.msj.success(`${res.messages[0]}`, "Başarılı", {
            progressBar: true,
          });
          this.spinner.hide();
        })
        .catch((err) => {
          this.spinner.hide();
          if (err.status > 399 || err.status < 500) {
            err.error.messages.forEach((element) => {
              return this.msj.warning(`${element}`, "Warning");
            });
            this.spinner.hide();
          }

          if (err.status > 499) {
            err.error.messages.forEach((element) => {
              return this.msj.error(`${element}`, "Error");
            });
            this.spinner.hide();
          }
          this.spinner.hide();
        });
      this.spinner.hide();
      return response;
    } catch (err) {
      this.spinner.hide();
      console.error(err);
      throw err;
    }
  }
}
