import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MenuJsonEmitService {
  constructor() {}
  private setMenuJson = new BehaviorSubject<any>(undefined);
  public getMenuJson: Observable<any> = this.setMenuJson;

  setEmitServis(value) {
    this.setMenuJson.next(value);
  }
}
