import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService, TOAST_CONFIG } from "ngx-toastr";
import { catchError, map, Observable, throwError } from "rxjs";
import { User } from "../model/user";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  baseURL = "https://cmsapi.monassist.com/api";
  headers = new HttpHeaders().set("Content-Type", "application/json");
  currentUser = {};
  constructor(
    private http: HttpClient,
    public router: Router,
    private toastr: ToastrService,
    private httpClient: HttpClient
  ) {}
  // Sign-up
  // signUp(user: User): Observable<any> {
  //     let api = `${this.baseURL}/register-user`;
  //     // return this.http.post(api, user).pipe(catchError(this.handleError));
  //     return false;
  // }
  // Sign-in
  signIn(user: User, userName) {
    return this.http
      .post<any>(`${this.baseURL}/Auth/Login`, user)

      .subscribe(
        (res: any) => {
          sessionStorage.setItem("user", JSON.stringify(res));
          sessionStorage.setItem("access_token", res.data.token);
          sessionStorage.setItem("refreshToken", res.data.refreshToken);
          this.username = userName;
          sessionStorage.setItem("username", userName);
          this.router.navigate(["dashboard/dashboard-page"]);
          this.toastr.success(`${res.messages[0]}`, `Success`);
        },
        (err) => {
          // if (err.status === 401) {
          //   this.router.navigateByUrl("/auth/login");
          //   sessionStorage.removeItem("access_token");
          //   sessionStorage.removeItem("user");
          //   window.location.reload();
          // }

          this.toastr.warning(`${err.error.messages[0]}`, `Warning`);
        }
      );
  }
  getToken() {
    return sessionStorage.getItem("access_token");
  }
  get isLoggedIn(): boolean {
    let authToken = sessionStorage.getItem("access_token");
    return authToken !== null ? true : false;
  }
  doLogout() {
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(["/auth/login"]);
    window.location.reload();
  }
  // // User profile
  // getUserProfile(id: any): Observable<any> {
  //     let api = `${this.baseURL}/user-profile/${id}`;
  //     return this.http.get(api, { headers: this.headers }).pipe(
  //         map((res) => {
  //             return res || {};
  //         }),
  //         catchError(this.handleError)
  //     );
  // }
  // Error
  handleError(error: any) {
    let msg = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = error.error.message;
      this.toastr.error("asdas");
    } else {
      // server-side error

      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(msg);
  }

  getUserFromStorage() {
    var userStr = window.sessionStorage.getItem("user");
    return userStr ? JSON.parse(userStr) : null;
  }
  username;
  refreshToken() {
    let user = JSON.parse(sessionStorage.getItem("user"));
    let refreshToken = user?.data?.refreshToken;
    let username = sessionStorage.getItem("username");
    let url = `${this.baseURL}/Auth/RefreshToken?kullaniciAdi=${username}&refreshToken=${refreshToken}`;

    return this.http.get<any>(url).pipe(
      catchError((error: any) => {
        if (error?.status == 400) {
          sessionStorage.removeItem("user");
          sessionStorage.removeItem("access_token");
          sessionStorage.clear();
          sessionStorage.removeItem("refreshToken");
        }

        return throwError(error);
      })
    );
  }
}
