<!-- footer start-->
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 footer-copyright text-center">
      <p class="mb-0">
        Copyright {{ today | date : "y" }} © by Monassist - CMS System -
        v1.0.064
      </p>
    </div>
  </div>
</div>
