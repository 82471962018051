import { Location } from "@angular/common";
import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.scss"],
})
export class BreadcrumbComponent implements OnInit {
  @Input() title: string;
  @Input() items: any[];
  @Input() active_item: string;
  locationH;

  constructor(private location: Location, private router: Router) {}

  getValue(value) {
    console.log(value);
    switch (value) {
      case "Personel Listesi":
        this.router.navigateByUrl("employee-module/employee-list");
        break;
      case "Personel Detay":
        this.router.navigateByUrl("employee-module/employee-detail");
        break;
      case "Şantiye Listesi":
        this.router.navigateByUrl("construction-module/construction-list");
        break;
      case "Şantiye Detay":
        this.router.navigateByUrl("construction-module/construction-detail");
        break;
      case "Müşteri Detay":
        this.router.navigateByUrl(
          "construction-module/construction-customer-list"
        );
        break;
      case "Müşteri Listesi":
        this.router.navigateByUrl(
          "construction-module/construction-customer-list"
        );
        break;

      case "Materyal Listesi":
        this.router.navigateByUrl("material-module/material-list");
        break;

      case "Materyal Detay":
        this.router.navigateByUrl("material-module/material-detail");
        break;

      default:
        break;
    }
  }

  back() {
    // navigate to previous page
    this.location.back();
  }

  ngOnInit() {}
}
