<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>
          <div>
            <a class="logo" routerLink="/">
              <img
                width="150"
                height="150"
                class="img-fluid for-light"
                src="https://monassist.com/img/monLogoMiddle.png"
                alt="looginpage"
              />
              <img
                width="150"
                height="150"
                class="img-fluid for-dark"
                src="https://monassist.com/img/monLogoMiddle.png"
                alt="looginpage"
              />
            </a>
          </div>
          <div class="login-main">
            <form class="theme-form" [formGroup]="loginForm">
              <h4>Sign in to account</h4>
              <p>Enter your User Name & Password to login</p>
              <div class="form-group">
                <label class="col-form-label">User Name</label>
                <input
                  class="form-control"
                  type="text"
                  [(ngModel)]="user"
                  required=""
                  placeholder=""
                  formControlName="userName"
                />
                <div
                  *ngIf="
                    loginForm.controls.userName.touched &&
                    loginForm.controls.userName.errors?.required
                  "
                  class="text text-danger mt-1"
                >
                  User Name is required
                </div>
                <div
                  *ngIf="
                    loginForm.controls.userName.touched &&
                    loginForm.controls.userName.errors?.userName
                  "
                  class="text text-danger mt-1"
                >
                  Invalid User Name
                </div>
              </div>
              <div class="form-group">
                <label class="col-form-label">Password</label>
                <input
                  class="form-control"
                  [(ngModel)]="password"
                  [type]="show ? 'text' : 'password'"
                  formControlName="password"
                  required=""
                  placeholder="*********"
                />
                <div class="show-hide" (click)="showPassword()" *ngIf="!show">
                  <app-feather-icons [icon]="'eye'"></app-feather-icons>
                </div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show">
                  <app-feather-icons [icon]="'eye-off'"></app-feather-icons>
                </div>
                <div
                  *ngIf="
                    loginForm.controls.password.touched &&
                    loginForm.controls.password.errors?.required
                  "
                  class="text text-danger mt-1"
                >
                  Password is required
                </div>
              </div>
              <div class="form-group mb-0">
                <div class="auth pt-2">
                  <label>Verification</label>
                  <div
                    class="d-flex flex-sm-row flex-column column-gap-3 row-gap-2"
                  >
                    <div class="d-flex flex-row gap-1 w-100 align-self-center">
                      <div>
                        <img
                          src="{{ captchaData }}"
                          class="rounded"
                          style="border-radius: 6px"
                        />
                      </div>
                      <button class="btn-light rounded px-2 pt-2" type="button">
                        <app-feather-icons
                          (click)="captcha()"
                          [icon]="'refresh-cw'"
                        ></app-feather-icons>
                      </button>
                    </div>
                    <input
                      class="form-control w-100"
                      type="text"
                      required=""
                      placeholder="Secure Code"
                      formControlName="recaptcha"
                      [ngClass]="{ 'is-invalid': f['recaptcha'].errors }"
                      [(ngModel)]="getCode"
                    />
                  </div>
                </div>

                <!-- <div class="form-group mb-4 w-full">
                  <div class="d-flex align-items-center">
                    <div>
                      <img
                        src="{{ captchaData }}"
                        class="rounded"
                        style="border-radius: 6px"
                      />
                    </div>

                    <div class="card mt-auto mb-auto">
                      <app-feather-icons
                        (click)="captcha()"
                        [icon]="'refresh-cw'"
                      ></app-feather-icons>
                    </div>
                  </div>

                  <div class="mt-3 w-100">
                    <input
                      type="text"
                      class="w-48"
                      [ngClass]="{
                        'is-invalid': f['recaptcha'].errors
                      }"
                      formControlName="recaptcha"
                      [(ngModel)]="getCode"
                      placeholder="Doğrulama Kodu"
                    />
                  </div>
                </div> -->
                <a [routerLink]="'/authentication/forgot-password'" class="link"
                  >Forgot password?</a
                >

                <div class="text-end mt-3">
                  <button
                    class="btn btn-primary d-block w-100"
                    [disabled]="!loginForm.valid"
                    (click)="login()"
                    type="button"
                  >
                    <span>Sign in</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
