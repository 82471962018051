import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class I18nService {
  constructor() {}
  private setEmit = new BehaviorSubject<any>(undefined);
  public getEmit: Observable<any> = this.setEmit;

  getEmitServis(value: any) {
    this.setEmit.next(value);
  }
}
