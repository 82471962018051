<ngx-loading-bar
  [color]="'#7366ff'"
  [includeSpinner]="false"
  [height]="'4px'"
></ngx-loading-bar>
<app-loader></app-loader>
<app-tap-to-top></app-tap-to-top>
<router-outlet></router-outlet>
<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="ball-clip-rotate-pulse"
  [fullScreen]="true"
  ><p style="color: white">Loading...</p></ngx-spinner
>
