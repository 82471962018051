<div class="container-fluid mb-3 pt-2">
  <div class="page-title d-flex align-items-center row px-2 m-0">
    <div class="d-flex justify-content-between align-items-center">
      <div class="col-2">
        <div class="backButton" (click)="back()">
          <i class="fa fa-angle-left" aria-hidden="true"></i>
        </div>
        <!-- <h3>{{ title }}</h3> -->
      </div>
      <div class="col-10">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="'/dashboard/default'">
              <app-svg-icon [icon]="'home'"></app-svg-icon>
            </a>
          </li>
          <li
            class="breadcrumb-item middleBread"
            (click)="getValue(item)"
            *ngFor="let item of items"
          >
            {{ item }}
          </li>
          <li class="breadcrumb-item active">{{ active_item }}</li>
        </ol>
      </div>
    </div>
  </div>
</div>
